.btn-blank {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  box-shadow: none !important;
}
.app-dropdown {
  border: none !important;
  box-shadow: none !important;
  color: $primary-color;
}
.app-dropdown:hover {
  border: none !important;
  outline: none !important;
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}
.page-title {
  color: $primary-color;
  margin: 20px 0;
}

tbody tr:nth-child(odd) {
  background-color: $light-grey;
}

tr {
  background-color: #fff;
}

td {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  margin: 20px;
}

td:first-child {
  padding-left: 20px;
  padding-right: 0;
}

.white-card {
  box-shadow: 1px 3px 12px -4px rgba(119, 69, 69, 0.75);
  -webkit-box-shadow: 1px 3px 12px -4px rgba(119, 69, 69, 0.75);
  -moz-box-shadow: 1px 3px 12px -4px rgba(119, 69, 69, 0.75);
  border-radius: 5px;
  background-color: #fff;
}
.card-title {
  color: black;
}
.horizontal-line {
  width: "100%";
  height: 2px;
  background-color: $divider-color;
  margin: 10px 0;
}

.app-btn {
  background-color: $primary-color;
  color: #fff;
  font-weight: bold;
}
.app-btn:hover {
  background-color: $primary-color;
  color: #fff;
  font-weight: bold;
}
.app-btn:focus {
  background-color: $primary-color;
  color: #fff;
  font-weight: bold;
}
.app-row {
  display: flex;
  align-items: center;
}
.justify-end {
  justify-content: end;
}
.add-btn-container {
  float: right;
  margin-bottom: 10px;
}
.error-text {
  font-size: 0.9rem;
  color: #c22626;
  font-weight: 500;
}
