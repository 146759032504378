$primary-color: #033703;
$secondary-color: #32050e;
$light-grey: #f3f3f3;
$app-background: #f6f6f6;
$divider-color: #dbd5d5;

$navy-blue: #011627;

$brown: #2c2c34;
$light-navy-blue: #04223a;

$sidebar-width: 250px;

$navbar-height: 60px;
