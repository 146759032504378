.login-card {
  background-color: #fff;
  border-radius: 15px;
  padding: 30px 40px;
  -webkit-box-shadow: 3px 9px 45px -6px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 3px 9px 45px -6px rgba(0, 0, 0, 0.43);
  box-shadow: 3px 9px 45px -6px rgba(0, 0, 0, 0.43);
  @include respond-below(xxs) {
    height: 100vh;
    width: 100vw;
  }
}
.auth-title {
  color: $primary-color;
  margin-top: 20px;
}
.app-input:focus {
  border-color: $primary-color;
  background-color: transparent;
  color: #000;
}
.login-input {
  background-color: transparent;
  color: #000;
}
.auth-btn {
  width: 100%;
  background-color: $primary-color;
  color: #fff;
  font-weight: bold;
}
.auth-btn:hover {
  background-color: $primary-color;
  color: #fff;
  font-weight: bold;
}
.login-label {
  color: white;
}
.register-link {
  color: blue;
}
