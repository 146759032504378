@mixin sidebar-transition {
  transition: 0.5s ease;
}

//Responsive Breakpoints

$breakpoints: (
  xxs: 420px,
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px,
);

@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (min-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}
