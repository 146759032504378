.edit-btn {
  color: rgb(43, 43, 158);
  font-weight: bold;
}
.add-btn-text {
  color: $primary-color;
  font-weight: bold;
  font-size: 0.9em;
}
.download-btn {
  background-color: $primary-color;
  color: #ffffff;
  padding: 9px 15px;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
}
.download-btn:hover {
  color: #ffffff;
}
