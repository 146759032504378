.user-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.sidebar-container {
  height: 100vh;
}

.app-container {
  display: flex;
  flex-direction: row;
}

.right-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  overflow: scroll;
  background-color: $app-background;
}

.app-content-container {
  margin-top: 60px;
}

.top-nav-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  background-color: #1d1d1d;
  color: white;
  padding: 10px 20px;
}

.page-container {
  padding: 5%;
  @include respond-below(sm) {
    padding-top: 35px 15px;
  }
}
