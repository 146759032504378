.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  @include respond-below(xs) {
    margin: 25px 0;
  }

  thead tr {
    background-color: $primary-color;
    color: #fff;
    text-align: left;
    font-weight: normal;
  }
  th,
  td {
    padding: 12px 15px;
  }
  tbody tr {
    border-bottom: 1px solid #f5f0f0;
    background-color: #fff;
    font-weight: 500;
  }
  tbody tr:nth-of-type(even) {
    background-color: rgb(246, 242, 242);
  }
  tbody tr:last-of-type {
    border-bottom: 2px solid $primary-color;
  }
  tbody tr:hover {
    font-weight: 700;
    color: $primary-color;
    background-color: #fff;
  }
}
